<template>
  <div class="form">
    <v-form
      v-model="valid"
    >
      <v-expansion-panels
        mandatory
        flat
        v-if="marketplace"
      >
        <v-expansion-panel>
          <v-expansion-panel-header> {{ $t('general') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col
                cols="12"
              >
                <v-text-field
                  :label="$t('title')"
                  type="text"
                  required
                  v-model="marketplace.name"
                  :rules="[rules.required]"
                  outlined
                />
                <v-text-field
                  :label="$t('description')"
                  type="text"
                  required
                  v-model="marketplace.description"
                  :rules="[rules.required]"
                  outlined
                />
                <v-select
                  :label="$t('language')"
                  type="text"
                  required
                  v-model="marketplace.defaultLanguage"
                  :rules="[rules.required]"
                  outlined
                  :items="availableLanguages"
                  item-text="name"
                  item-value="key"
                />
                <v-autocomplete
                  :label="$t('defaultCountry')"
                  type="text"
                  required
                  v-model="marketplace.defaultCountry"
                  :rules="[rules.required]"
                  outlined
                  item-text="name"
                  item-value="code"
                  :items="countries"
                />
                <v-autocomplete
                  :label="$t('currency')"
                  type="text"
                  required
                  v-model="marketplace.defaultCurrency"
                  :rules="[rules.required]"
                  outlined
                  item-text="name"
                  item-value="code"
                  :items="currencies"
                />
                <v-select
                  :label="$t('phoneExtensions')"
                  type="text"
                  required
                  v-model="marketplace.phoneExtensions"
                  :rules="[rules.required]"
                  outlined
                  :items="phoneExtensions"
                  multiple
                />
                <v-select
                  :label="$t('defaultPhoneExtension')"
                  type="text"
                  required
                  v-model="marketplace.defaultPhoneExtension"
                  :rules="[rules.required]"
                  outlined
                  :items="phoneExtensions"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> {{ $t('integrations') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col
                cols="12"
              >
                <v-text-field
                  :label="$t('namespace')"
                  type="text"
                  required
                  v-model="marketplace.namespace"
                  :rules="[rules.required]"
                  outlined
                  :disabled="marketplace.id !== null && typeof marketplace.id !=='undefined'"
                />
                <v-text-field
                  :label="$t('urlScheme')"
                  type="text"
                  required
                  v-model="marketplace.urlScheme"
                  :rules="[rules.required]"
                  outlined
                />
                <v-text-field
                  :label="$t('bundleId')"
                  type="text"
                  required
                  v-model="marketplace.bundleId"
                  :rules="[rules.required]"
                  outlined
                  :disabled="marketplace.id !== null && typeof marketplace.id !=='undefined'"
                />
                <v-text-field
                  :label="$t('appleAppId')"
                  type="text"
                  required
                  v-model="marketplace.appleAppId"
                  :rules="[rules.required]"
                  outlined
                  :disabled="marketplace.id !== null && typeof marketplace.id !=='undefined'"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> {{ $t('texts') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col
                cols="12"
              >
                <v-text-field
                  :label="$t('intro')"
                  type="text"
                  required
                  v-model="marketplace.intro"
                  :rules="[rules.required]"
                  outlined
                />
                <v-text-field
                  :label="$t('displayName')"
                  type="text"
                  required
                  v-model="marketplace.displayName"
                  :rules="[rules.required]"
                  outlined
                />
                <v-text-field
                  :label="$t('welcomeMessage')"
                  type="text"
                  required
                  v-model="marketplace.welcomeMessage"
                  :rules="[rules.required]"
                  outlined
                />
                <v-text-field
                  :label="$t('welcomeSubMessage')"
                  type="text"
                  required
                  v-model="marketplace.welcomeSubMessage"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-for="(panel, panelIndex) in visualPanels"
          :key="panelIndex"
        >
          <v-expansion-panel-header> {{ panel.title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col
                v-for="(field, fieldIndex) in panel.fields"
                :key="fieldIndex"
                cols="12"
              >
                <v-card
                  outlined
                  class="mb-4"
                >
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-if="field.type === 'textarea'"
                          :label="field.label"
                          type="text"
                          required
                          v-model="marketplace[field.key]"
                          :rules="[rules.required]"
                          outlined
                          hide-details
                        />
                        <v-text-field
                          v-if="field.type === 'textfield'"
                          :label="field.label"
                          type="text"
                          required
                          v-model="marketplace[field.key]"
                          :rules="[rules.required]"
                          outlined
                          hide-details
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              v-on="on"
                              @click="previewContent(marketplace[field.key], field.contentType)"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          {{ $t('previewContent') }}
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              v-on="on"
                              @click="copyContent(marketplace[field.key])"
                            >
                              <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                          </template>
                          {{ $t('copyContentToClipboard') }}
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              v-on="on"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </template>
                          {{ field.information }}
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ this.$t('tabs') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content :key="forceRenderKey">
            <MarketplaceTabList
              :marketplace-tabs="marketplace.tabs"
              @removeTab="removeTab"
              @addTab="addTab"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> {{ $t('styles') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col
                cols="12"
              >
                <div class="pb-5">
                  <ColorSelect
                    :label="$t('primaryColor')"

                    type="text"
                    required
                    v-model="marketplace.primaryColor"
                    :rules="[rules.required]"
                    outlined
                    hide-details
                    :apply-manually="true"
                  />
                </div>
                <div class="pb-5">
                  <ColorSelect
                    :label="$t('secondaryColor')"
                    type="text"
                    required
                    v-model="marketplace.secondaryColor"
                    :rules="[rules.required]"
                    outlined
                    hide-details
                    :apply-manually="true"
                  />
                </div>
                <div class="pb-5">
                  <ColorSelect
                    :label="$t('baseFontColor')"
                    type="text"
                    required
                    v-model="marketplace.baseFontColor"
                    :rules="[rules.required]"
                    outlined
                    hide-details
                    :apply-manually="true"
                  />
                </div>
                <div class="pb-5">
                  <ColorSelect
                    :label="$t('headingFontColor')"
                    type="text"
                    required
                    v-model="marketplace.headingFontColor"
                    :rules="[rules.required]"
                    outlined
                    hide-details
                    :apply-manually="true"
                  />
                </div>
                <div class="pb-5">
                  <ColorSelect
                    :label="$t('buttonFontColor')"
                    type="text"
                    required
                    v-model="marketplace.buttonFontColor"
                    :rules="[rules.required]"
                    outlined
                    hide-details
                    :apply-manually="true"
                  />
                </div>
                <v-autocomplete
                  :label="$t('baseFontFamily')"
                  item-value="family"
                  item-text="family"
                  :items="fonts"
                  required
                  v-model="marketplace.baseFontFamily"
                  :rules="[rules.required]"
                  outlined
                />

                <v-autocomplete
                  :label="$t('headingFontFamily')"
                  item-value="family"
                  item-text="family"
                  :items="fonts"
                  required
                  v-model="marketplace.headingFontFamily"
                  :rules="[rules.required]"
                  outlined
                />
                <v-autocomplete
                  :label="$t('buttonFontFamily')"
                  item-value="family"
                  item-text="family"
                  :items="fonts"
                  required
                  v-model="marketplace.buttonFontFamily"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </div>
</template>

    <script>
    import MarketplaceTabList from "./MarketplaceTabList.vue"
    import ColorSelect from '../common/ColorSelect.vue'
    export default {
      name: 'MarketplaceForm',
      props: {
          value: {
              type: Object,
              default: () => {}
          },
          triggerUpdate: {
              type: Boolean,
              default: false
          },
      },
      components: {
        ColorSelect,
        MarketplaceTabList
      },
      data() {
        return {
          valid: false,
          selectedItem: null,
          forceRenderKey: 0,
          fonts: []
        }
      },
      computed: {
        requiredValuesFilled() {
          if(!this.marketplace.name || !this.marketplace.description || !this.marketplace.defaultLanguage ||
          !this.marketplace.defaultCountry || !this.marketplace.defaultCurrency || !this.marketplace.phoneExtensions ||
          !this.marketplace.defaultPhoneExtension) {
            return false
          }

          if(!this.marketplace.namespace || !this.marketplace.urlScheme || !this.marketplace.bundleId || !this.marketplace.appleAppId) {
            return false
          }

          if(!this.marketplace.intro || !this.marketplace.displayName || !this.marketplace.welcomeMessage || !this.marketplace.welcomeSubMessage) {
            return false
          }

          if(!this.marketplace.heroImage || !this.marketplace.homeImage || !this.marketplace.brightLogo || !this.marketplace.darkLogo ||
          !this.marketplace.appIcon) {
            return false
          }

          if(!this.marketplace.primaryColor || !this.marketplace.secondaryColor || !this.marketplace.baseFontColor || !this.marketplace.headingFontColor ||
          !this.marketplace.buttonFontColor || !this.marketplace.baseFontFamily || !this.marketplace.headingFontFamily || !this.marketplace.buttonFontFamily) {
            return false
          }

          return true
        },
        marketplace: {
          get() {
            return this.value
          },
          set(value) {
            this.$emit('input', value)
          }
        },
        visualPanels() {
          return [
            {
              title: this.$t('images'),
              key: 'images',
              fields: [
                {
                  key: 'heroImage',
                  label: this.$t('heroImage'),
                  type: 'textfield',
                  required: true,
                  information: this.$t('heroImageInformation'),
                  contentType: 'img'
                },
                {
                  key: 'homeImage',
                  label: this.$t('homeImage'),
                  type: 'textfield',
                  required: true,
                  information: this.$t('homeImageInformation'),
                  contentType: 'img'
                }
              ]
            },
            {
              title: this.$t('logosAndIcons'),
              key: 'logosAndIcons',
              fields: [
                {
                  key: 'brightLogo',
                  label: this.$t('brightLogo'),
                  type: 'textarea',
                  required: true,
                  information: this.$t('brightLogoInformation'),
                  contentType: 'html'
                },
                {
                  key: 'darkLogo',
                  label: this.$t('darkLogo'),
                  type: 'textarea',
                  required: true,
                  information: this.$t('darkLogoInformation'),
                  contentType: 'html'
                },
                {
                  key: 'appIcon',
                  label: this.$t('appIcon'),
                  type: 'textarea',
                  required: true,
                  information: this.$t('appIconInformation'),
                  contentType: 'img'
                }
              ]
            }
          ]
        },
        rules() {
          return this.$store.getters.rules
        },
        items() {
          return this.$store.state.items.map(item => {
            return {
              uuid: item.uuid,
              title: item.title
            }
          })
        },
        availableLanguages() {
          return this.$store.state.languages
        },
        currencies() {
          return this.$store.state.currencies
        },
        countries() {
          return this.$store.state.countries
        },
        phoneExtensions() {
          return ['+354', '+44', '+46', '+45', '+353']
        },
        getGoogleFonts() {
          return this.$store.state.googleFonts
        }
      },
      methods: {
        previewContent(content, contentType) {
          this.$store.commit('updateGenericDialog', {
            'component': 'components/common/PreviewContent',
            'props': {
              'content': content,
              'type': contentType
            }
          })
        },
        removeTab(index){
          this.marketplace.tabs.splice(index, 1)
          this.forceRenderKey++
        },
        addTab() {
          if(!this.marketplace.tabs || !Array.isArray(this.marketplace.tabs)) {
          this.marketplace.tabs = []
          }
          this.marketplace.tabs.push({
            icon: null,
            title: null,
            path: null,
            showInApp: true,
            key: null
          })
          this.forceRenderKey++
        },
        copyContent(content) {
          navigator.clipboard.writeText(content)
        }
      },
      watch: {
        valid(value) {
          const allValuesValid = value && this.requiredValuesFilled
          this.$emit('valid', allValuesValid)
        }
      },
      created() {
        this.$store.dispatch('getGoogleFonts').then(result => {
          this.fonts = result
        })
      }
    }
    </script>

    <style scoped>
          .v-expansion-panel {
          border: thin solid rgba(0, 0, 0, 0.12);
          border-radius: 8px
        }

        .v-expansion-panels {
          border-radius: 8px
        }

        .v-label {
          margin-top: 14px
        }

        .v-label--active {
          margin-top: 0px;
        }
        .form {
          max-height: 800px;
        }
    </style>
