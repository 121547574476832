<template>
  <v-col
    cols="12"
    class="pt-0 text-center"
  >
    <v-list v-if="tabs && tabs.length > 0">
      <template v-for="(tab, index) in tabs">
        <v-list-item 
          :key="index"
        >
          <v-list-item-content>
            <v-card
              outlined
              style="border-color:black"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0 px-6"
                >
                  <v-text-field 
                    v-model="tab.title"
                    outlined
                    :label="$t('title')"
                    class="pt-5"
                    disable-updates
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0 px-6"
                >
                  <v-text-field 
                    v-model="tab.icon"
                    outlined
                    :label="$t('icon')"
                    class=""
                    disable-updates
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0 px-6"
                >
                  <v-text-field 
                    v-model="tab.path"
                    outlined
                    :label="$t('path')"
                    class=""
                    disable-updates
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0 px-6"
                >
                  <v-text-field 
                    v-model="tab.key"
                    outlined
                    :label="$t('key')"
                    class=""
                    disable-updates
                  />
                </v-col>
              </v-row>
              <v-row
                align="center"
                justify="center"
              >
                <v-col
                  cols="6"
                >
                  <v-switch
                    v-model="tab.showInApp"
                    :label="$t('showInApp')"
                    class="ml-6"
                  />
                </v-col>
                <v-col
                  cols="5"
                >
                  <v-btn
                    outlined
                    color="error"
                    @click="removeTab(index)"
                  >
                    {{ $t('delete') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-alert
      v-else
      type="info"
      outlined
    >
      {{ $t('hereYouCanDefineTheConditionsThatWillInvokeTheOffer') }}
    </v-alert>
    <v-btn
      outlined
      @click="addTab"
    >
      {{ $t('addTab') }}
      <v-icon right>
        mdi-plus
      </v-icon>
    </v-btn>
  </v-col>
</template>
  
  <script>
  export default {
      name: 'MarketplaceTabList', 
      props: {
          marketplaceTabs: {
              type: Array, 
              default: () => []
          }
      }, 
      computed: {
        tabs: {
          get() {
            return this.marketplaceTabs
          },
          set(value) {
            this.$emit('input', value)
          }
        }
      },
      methods: {
          addTab() {
            this.$emit('addTab')
          },
          removeTab(index) {
            this.$emit('removeTab', index)
          }
      }
  }
  </script>
  